import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.slider()
  }

  slider() {
    const slide = this.element //スライダー親
    const slideItem = slide.querySelectorAll('.MainVisual__image') //スライド要素
    const totalNum = slideItem.length - 1 //スライドの枚数を取得
    const FadeTime = 2000 //フェードインの時間
    const IntarvalTime = 5000 //クロスフェードさせるまでの間隔
    let actNum = 0 //現在アクティブな番号
    let nowSlide //現在表示中のスライド
    let NextSlide //次に表示するスライド

    // スライドの1枚目をフェードイン
    setTimeout(() => {
        slideItem[0].classList.add('-show', '-zoom');
    }, 0)

    setInterval(() => {
      if (actNum < totalNum) {
        let nowSlide = slideItem[actNum]
        let NextSlide = slideItem[++actNum]
        //.-show 削除でフェードアウト
        nowSlide.classList.remove('-show')
        // と同時に、次のスライドがズームしながらフェードインする
        NextSlide.classList.add('-show', '-zoom')
        //フェードアウト完了後、.-zoom 削除
        setTimeout(() => {
            nowSlide.classList.remove('-zoom')
        }, FadeTime)
      } else {
        let nowSlide = slideItem[actNum]
        let NextSlide = slideItem[actNum = 0]
        //.-show削除でフェードアウト
        nowSlide.classList.remove('-show')
        // と同時に、次のスライドがズームしながらフェードインする
        NextSlide.classList.add('-show', '-zoom')
        //フェードアウト完了後、.-zoom削除
        setTimeout(() => {
            nowSlide.classList.remove('-zoom')
        }, FadeTime)
      }
    }, IntarvalTime)
  }
}
