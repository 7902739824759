import { Controller } from 'stimulus'
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
export default class extends Controller {
  initialize() {
    this.url = 'https://arbeee.net/api/course-search.php'
  }

  connect() {
    this.fetchApi()
  }

  fetchApi() {
    const getParameter = new URL(window.location).searchParams.get('paging')
    const param = getParameter ? `?paging=${getParameter}` : ''
    axios
      .get(this.url + param)
      .then(response => {
        this.appendHtml(response.data)
      })
      .catch(e => {
        console.error(e)
      })
  }

  appendHtml(data) {
    this.element.innerHTML = data
  }
}
