import { Controller } from 'stimulus'
import Flickity from 'flickity-imagesloaded'

export default class extends Controller {
  static get targets() {
    return ['slides', 'slide']
  }

  connect() {
    this.flickity = new Flickity(this.slidesTarget, {
      groupCells: !this.isLarge,
      cellAlign: 'left',
      contain: !this.isLarge,
      percentPosition: false,
      prevNextButtons: false,
      imagesLoaded: true
    })

    // スライド枚数が１枚ないし０枚のとき
    // 左右ボタンとスライド一覧を消すためのクラス名を付与する
    if (this.slidesSize <= 1) {
      this.element.classList.add('-onlyItem')
    }
  }

  disconnect() {
    this.flickity.destroy()
  }

  get slidesSize() {
    return this.flickity.slides.length
  }

  get hasPrev() {
    const index = this.flickity.selectedIndex
    return index !== 0
  }

  get hasNext() {
    const index = this.flickity.selectedIndex
    return index < this.slidesSize - 1
  }

  get isLarge() {
    return this.element.classList.contains('-large')
  }

  goPrev() {
    if (!this.hasPrev) {
      this.goLast()
    } else {
      this.flickity.previous()
    }
  }

  goNext() {
    if (!this.hasNext) {
      this.goFirst()
    } else {
      this.flickity.next()
    }
  }

  goFirst() {
    this.select(0)
  }

  goLast() {
    this.select(this.slidesSize - 1)
  }

  select(index) {
    this.flickity.select(index)
  }
}
