import { Controller } from 'stimulus'
import _ from 'underscore'

export default class extends Controller {
  static get targets() {
    return ['inner']
  }

  initialize() {
    this.scrollHandler = this.scrollHandler.bind(this)
    this.resizeHandler = _.debounce(this.resizeHandler, 300).bind(this)
  }

  connect() {
    this.image = this.element.querySelector('img')
    this.bindEvents()
    this.scrollHandler()
    this.resizeHandler()
  }

  disconnect() {
    this.unbindEvents()
  }

  bindEvents() {
    this.innerTarget.addEventListener('scroll', this.scrollHandler, false)
    window.addEventListener('resize', this.resizeHandler, false)
  }

  unbindEvents() {
    this.innerTarget.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.resizeHandler)
  }

  /**
   * テーブル内のスクロールイベントのハンドラ
   * - 終端までスクロールしたら `-scrolled` クラスを付与する
   */
  scrollHandler() {
    const containerWidth = this.innerTarget.clientWidth
    const imageWidth = this.image.clientWidth

    if (containerWidth >= imageWidth) {
      return
    }

    const scrolledToEnd = this.innerTarget.scrollLeft === imageWidth - containerWidth
    if (scrolledToEnd) {
      this.element.classList.add('-scrolled')
    } else {
      this.element.classList.remove('-scrolled')
    }
  }

  /**
   * ウィンドウリサイズのハンドラ
   * - スクロールが必要かどうかを判定して `-hasScroll` クラスを付与する
   */
  resizeHandler() {
    const containerWidth = this.innerTarget.clientWidth
    let imageWidth = this.image.width
    const loadImage = () => {
      return new Promise(resolve => {
        const image = new Image()
        if (image.complete) resolve(image)
        image.onload = image.onerror = resolve
        image.src = this.image.src
      })
    }

    loadImage()
      .then(res => {
        imageWidth = res.width
        if (containerWidth < imageWidth) {
          this.element.classList.add('-hasScroll')
        } else {
          this.element.classList.remove('-hasScroll')
        }
      })
      .catch(e => {
        console.error(e)
      })
  }
}
