import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  scroll(e) {
    e.preventDefault()
    const speed = 500
    const href = $(this.element).attr('href')
    const target = $(href == '#' || href == '' ? 'html' : href)
    const position = target.offset().top
    $('body,html').animate({ scrollTop: position }, speed, 'swing')
  }
}
