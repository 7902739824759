import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * SNS シェアボタンを持つコンテナに適用されるコントローラー。
 * リンクの href 属性に含まれる {url} 等を置換する。
 */
export default class extends Controller {
  connect() {
    const currentPageUrl = this.getCurrentPageUrl()
    $(this.element)
      .find('a[href]')
      .each((i, el) => {
        const $el = $(el)
        const replacedUrl = this.replaceUrl($el.attr('href'), currentPageUrl)
        $el.attr('href', replacedUrl)
      })
  }

  getCurrentPageUrl() {
    let url = location.href

    const canonicalLink = document.head.querySelector('link[rel="canonical"]')
    if (canonicalLink) {
      url = canonicalLink.getAttribute('href')
    }

    return url
  }

  replaceUrl(string, url) {
    url = encodeURIComponent(url)
    return string.replace(/{url}/g, url)
  }
}
