import { Controller } from 'stimulus'
import _ from 'underscore'

export default class extends Controller {
  static get targets() {
    return ['inner']
  }

  initialize() {
    this.scrollHandler = this.scrollHandler.bind(this)
    this.resizeHandler = _.debounce(this.resizeHandler, 300).bind(this)
  }

  connect() {
    this.table = this.element.querySelector('table')
    this.bindEvents()
    this.scrollHandler()
    this.resizeHandler()
  }

  disconnect() {
    this.unbindEvents()
  }

  bindEvents() {
    this.innerTarget.addEventListener('scroll', this.scrollHandler, false)
    window.addEventListener('resize', this.resizeHandler, false)
  }

  unbindEvents() {
    this.innerTarget.removeEventListener('scroll', this.scrollHandler)
    window.removeEventListener('resize', this.resizeHandler)
  }

  /**
   * テーブル内のスクロールイベントのハンドラ
   * - 終端までスクロールしたら `-scrolled` クラスを付与する
   */
  scrollHandler() {
    const containerWidth = this.innerTarget.clientWidth
    const tableWidth = this.table.clientWidth

    if (containerWidth >= tableWidth) {
      return
    }

    const scrolledToEnd = this.innerTarget.scrollLeft === tableWidth - containerWidth
    if (scrolledToEnd) {
      this.innerTarget.classList.add('-scrolled')
    } else {
      this.innerTarget.classList.remove('-scrolled')
    }
  }

  /**
   * ウィンドウリサイズのハンドラ
   * - スクロールが必要かどうかを判定して `-hasScroll` クラスを付与する
   */
  resizeHandler() {
    const containerWidth = this.innerTarget.clientWidth
    const tableWidth = this.table.clientWidth

    if (containerWidth < tableWidth) {
      this.innerTarget.classList.add('-hasScroll')
    } else {
      this.innerTarget.classList.remove('-hasScroll')
    }
  }
}
