import 'picturefill'
import 'what-input'
import 'web-animations-js'
import 'intersection-observer'

import objectFitImages from 'object-fit-images'
objectFitImages()

// iOS13.1のSafariで発生するカルーセルがスワイプできないflickityのバグ対応
// https://github.com/metafizzy/flickity/issues/959
// https://github.com/metafizzy/flickity/issues/740#issuecomment-474601361
// https://gist.github.com/bakura10/b0647ef412eb7757fa6f0d2c74c1f145
var touchingCarousel = false,
  touchStartCoords
document.body.addEventListener('touchstart', function(e) {
  if (e.target.closest('.flickity-slider')) {
    touchingCarousel = true
  } else {
    touchingCarousel = false
    return
  }
  touchStartCoords = {
    x: e.touches[0].pageX,
    y: e.touches[0].pageY
  }
})
document.body.addEventListener(
  'touchmove',
  function(e) {
    if (!(touchingCarousel && e.cancelable)) {
      return
    }
    var moveVector = {
      x: e.touches[0].pageX - touchStartCoords.x,
      y: e.touches[0].pageY - touchStartCoords.y
    }
    if (Math.abs(moveVector.x) > 7) e.preventDefault()
  },
  { passive: false }
)
// end
