import { Controller } from 'stimulus'
import Flickity from 'flickity-imagesloaded'

export default class extends Controller {
  static get targets() {
    return ['slides']
  }

  initialize() {
    this.mqList = matchMedia('(max-width: 767px)')
    this.handleMqList = this.handleMqList.bind(this)
  }

  connect() {
    this.mqList.addListener(this.handleMqList)
    this.initializeFlickityIfNeeded()
  }

  disconnect() {
    this.destroyFlickity()
  }

  handleMqList() {
    if (this.mqList.matches) {
      this.initializeFlickityIfNeeded()
    } else {
      this.destroyFlickity()
    }
  }

  initializeFlickityIfNeeded() {
    if (this.flickity || !this.mqList.matches) {
      return
    }

    this.flickity = new Flickity(this.slidesTarget, {
      cellAlign: 'left',
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true
    })

    this.flickity.on('staticClick', (e, p, el, index) => this.select(index))
  }

  destroyFlickity() {
    if (this.flickity) {
      this.flickity.destroy()
      delete this.flickity
    }
  }

  select(index) {
    this.flickity.select(index)
  }
}
