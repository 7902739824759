import { Controller } from 'stimulus'
import _ from 'underscore'

export default class extends Controller {
  static get targets() {
    return ['item']
  }

  initialize() {
    this.template = _.template(template)
  }

  connect() {
    this.selectedIndex = this.getSelectedIndex()
    this.items = this.itemTargets.map((el, i) => {
      const en = el.querySelector('.CategoryNavigation__itemText1').textContent
      const ja = el.querySelector('.CategoryNavigation__itemText2').textContent
      return {
        href: el.href,
        selected: i === this.selectedIndex,
        en,
        ja
      }
    })
    const current = this.items[this.selectedIndex]
    const htmlToInsert = this.template({
      current,
      items: this.items
    })
    this.element.insertAdjacentHTML('beforeend', htmlToInsert)
    this.selectTarget = this.element.querySelector('.CategoryNavigation__select')
    this.bindEvents()
  }

  bindEvents() {
    this.selectTarget.addEventListener('change', () => this.handleSelectChange())
  }

  getSelectedIndex() {
    return _.findIndex(this.itemTargets, el => el.classList.contains('-current'))
  }

  handleSelectChange() {
    const item = this.items[this.selectTarget.selectedIndex]
    location.href = item.href
  }
}

const template = `
<p class="CategoryNavigation__sp">
  <span class="CategoryNavigation__value">
    <span><%- current.en %></span>
    <span><%- current.ja %></span>
  </span>
  <select class="CategoryNavigation__select">
    <% items.forEach(function(item) { %>
    <option
      <% if (item.selected) { %>selected<% } %>
      data-en="<%- item.en %>"
      data-ja="<%- item.ja %>"
    >
      <%- item.en %> <%- item.ja %>
    </option>
    <% }) %>
  </select>
</p>
`
