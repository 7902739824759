import { Controller } from 'stimulus'
import _ from 'underscore'
import LocalStorage from '../libs/local-storage'

export default class extends Controller {
  initialize() {
    this.storage = new LocalStorage('intro-loaded')
  }

  connect() {
    if (this.storage.get()) {
      this.hide()
      return
    }

    this.storage.set(true)
    this.element.classList.add('-shown')
    Promise.all([this.loadImages(document.images), this.resolveByTime(4800)]).then(() => {
      this.hide()
    })
  }

  hide() {
    document.body.classList.add('-loaded')
    this.element.classList.add('-done')
  }

  loadImages(images) {
    return Promise.all(_.map(images, img => this.loadImage(img)))
  }

  /**
   * @param {HTMLImageElement} img
   */
  loadImage(img) {
    return new Promise(resolve => {
      if (img.complete) resolve()
      img.onload = img.onerror = resolve
    })
  }

  resolveByTime(time) {
    return new Promise(resolve => {
      setTimeout(resolve, time)
    })
  }
}
