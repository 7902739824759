import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['label']
  }

  showAll() {
    this.labelTarget.textContent = '...'
    setTimeout(() => {
      this.element.classList.add('-showAll')
    }, 400)
  }
}
