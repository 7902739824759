export default class LocalStorage {
  constructor(storageKey) {
    this.storageKey = storageKey
  }

  has() {
    return !!this.get()
  }

  get() {
    return this._operate(storage => {
      const stored = storage.getItem(this.storageKey)
      return JSON.parse(stored)
    }, null)
  }

  set(value) {
    return this._operate(storage => {
      storage.setItem(this.storageKey, JSON.stringify(value))
    })
  }

  remove() {
    return this._operate(storage => {
      storage.removeItem(this.storageKey)
    })
  }

  /**
   * @callback operateCallback
   * @param {Storage} storage
   */
  /**
   * 例外処理を受け持つ中間関数
   * @param {operateCallback} operationCallback
   * @param {*} [fallback]
   * @private
   */
  _operate(operationCallback, fallback) {
    if (!('localStorage' in global)) {
      return fallback
    }
    try {
      return operationCallback(global.localStorage)
    } catch (e) {
      console.error(e)
      return fallback
    }
  }
}
