import { Controller } from 'stimulus'

export default class extends Controller {
  static get targets() {
    return ['anchor', 'thumbnail']
  }

  addHoverClass() {
    this.anchorTarget.classList.add('-hover')
    this.thumbnailTarget.classList.add('-hover')
  }

  removeHoverClass() {
    this.anchorTarget.classList.remove('-hover')
    this.thumbnailTarget.classList.remove('-hover')
  }
}
