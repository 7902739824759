import { Controller } from 'stimulus'
import Flickity from 'flickity-imagesloaded'
import $ from 'jquery'
import _ from 'underscore'

export default class extends Controller {
  static get targets() {
    return ['project', 'slides', 'slide']
  }

  initialize() {
    this.template = _.template(template)
    this.mqList = matchMedia('(max-width: 767px)')
  }

  connect() {
    this.updateProject(0)
    this.updateSlideContainerSize()

    // スライド枚数が１枚ないし０枚のとき
    // 左右ボタンとスライド一覧を消すためのクラス名を付与する
    if (this.slideTargets.length <= 1) {
      this.element.classList.add('-onlyProject')
      return
    }

    this.flickity = new Flickity(this.slidesTarget, {
      cellAlign: 'left',
      percentPosition: false,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true
    })

    this.flickity.on('change', index => this.updateProject(index))
    this.flickity.on('staticClick', (e, p, el, index) => this.select(index))
  }

  disconnect() {
    this.flickity.allOff()
  }

  /**
   * スライド枚数が少ないとき、スライド一覧を中央寄せにするための処理
   */
  updateSlideContainerSize() {
    const itemLength = this.slideTargets.length
    const itemWidth = this.mqList.matches ? 260 : 340
    const gutterWidth = this.mqList.matches ? 10 : 30
    this.slidesTarget.style.width = (itemWidth + gutterWidth) * itemLength - gutterWidth + 'px'
  }

  get hasPrev() {
    const index = this.flickity.selectedIndex
    return index !== 0
  }

  get hasNext() {
    const index = this.flickity.selectedIndex
    return index < this.slideTargets.length - 1
  }

  goPrev() {
    if (!this.hasPrev) {
      this.goLast()
    } else {
      this.flickity.previous()
    }
  }

  goNext() {
    if (!this.hasNext) {
      this.goFirst()
    } else {
      this.flickity.next()
    }
  }

  goFirst() {
    this.select(0)
  }

  goLast() {
    this.select(this.slideTargets.length - 1)
  }

  select(index) {
    this.flickity.select(index)
  }

  updateProject(index) {
    const data = this.scrapeData(this.slideTargets[index])
    const content = this.template(data)
    const $project = $(this.projectTarget)
    $project
      .empty()
      .stop(true, true)
      .css('opacity', '0')
      .append(content)
      .fadeTo(800, 1)
  }

  /**
   * @param {HTMLElement} slide
   */
  scrapeData(slide) {
    const title = slide.querySelector('[data-title]').textContent
    const image = slide.querySelector('[data-image]').src
    const subTitle = slide.dataset.subTitle
    const description = slide.dataset.description
    const link = slide.dataset.link
    const isBlank = slide.dataset.isBlank
    return { title, image, subTitle, description, link, isBlank }
  }
}

const template = `
  <h3 class="HomeSpecialProjects__projectTitle">
    <a href="<%- link %>" target="_blank">
      <%- title %>
    </a>
  </h3>
  <div class="HomeSpecialProjects__imageContainer">
    <a href="<%- link %>" target="_blank">
      <img class="HomeSpecialProjects__image" src="<%- image %>" alt="" />
    </a>
  </div>
  <div class="HomeSpecialProjects__projectDetail">
    <p class="HomeSpecialProjects__projectSubTitle"><%- subTitle %></p>
    <p class="HomeSpecialProjects__projectDescription"><%- description %></p>
    <p class="HomeSpecialProjects__projectDetailLink">
      <% if (isBlank) { %>
        <a href="<%- link %>" target="_blank">
          詳しく見る
          <span class="LinkIcon -blank" aria-label="新しいタブで開く"></span>
        </a>
      <% } else { %>
        <a href="<%- link %>">詳しく見る</a>
      <% } %>
    </p>
  </div>
`
