import { Controller } from 'stimulus'
import _ from 'underscore'

const THRESHOLD_UP_PC = 20
const THRESHOLD_UP_SP = 0
const THRESHOLD_DOWN = 140

const mqList = matchMedia('(max-width: 767px)')

export default class extends Controller {
  static get targets() {
    return ['inner2', 'toggleButton', 'menu']
  }

  initialize() {
    this.throttledScroll = _.throttle(this.handleScroll, 400).bind(this)
  }

  connect() {
    const observer = new IntersectionObserver(() => this.handleScroll(), {
      rootMargin: '-20px 0px 0px 0px',
      threshold: 1
    })
    observer.observe(this.element)
  }

  get isHome() {
    return this.element.classList.contains('-home')
  }

  get shown() {
    return this.element.classList.contains('-shown')
  }

  get opened() {
    return this.toggleButtonTarget.getAttribute('aria-expanded') === 'true'
  }

  set opened(opened) {
    this.toggleButtonTarget.setAttribute('aria-expanded', opened ? 'true' : 'false')
  }

  toggleMenu() {
    this.opened ? this.closeMenu() : this.openMenu()
    this.opened = !this.opened
  }

  openMenu() {
    this.opened = true
    this.menuTarget.classList.add('-shown')
    // this.menuTarget.focus()
  }

  closeMenu() {
    this.opened = false
    this.menuTarget.classList.remove('-shown')
    this.toggleButtonTarget.focus()
  }

  handleScroll() {
    if (window.pageYOffset >= THRESHOLD_DOWN) {
      if (!this.shown) {
        this.showHeader()
      }
    }
    const threshold = mqList.matches ? THRESHOLD_UP_SP : THRESHOLD_UP_PC
    if (window.pageYOffset <= threshold) {
      if (this.shown) {
        this.hideHeader()
      }
    }
  }

  showHeader() {
    this.element.classList.add('-shown')
  }

  hideHeader() {
    this.element.classList.remove('-shown')
  }
}
